










import {Component, Prop, Vue} from "vue-property-decorator";

@Component({})
export default class ProductHuntButton extends Vue {
  @Prop({default: 55}) height!: number;
}
