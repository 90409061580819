






import Vue from 'vue'

export default Vue.extend({
    name: "brand-linkedin",
    props: [
        'color',
        'icon'
    ],
    methods: {
        redirect() {
            window.open("https://www.linkedin.com/company/lokapp", "_blank");
        }
    }
})
