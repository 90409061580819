






import Vue from 'vue'

export default Vue.extend({
  name: "brand-twitter",
  props: [
    'color',
    'icon'
  ],
  methods: {
    redirect() {
      window.open("https://twitter.com/Lokapp_io", "_blank");
    }
  }
})
