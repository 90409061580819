
























import Vue from 'vue'
import CTAButton from '../CTAButton.vue'

export default Vue.extend({
    name: "section-projects",
    components: {
        'cta-button': CTAButton
    },
    computed: {
        imageHeight (): string {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return '300px'
                case 'sm': return '350px'
                case 'md': return '600px'
                case 'lg': return '800px'
                case 'xl': return '1000px'
            }
            return '0px';
        },
    } 
})
