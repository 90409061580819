













import Vue from 'vue'

export default Vue.extend({
    name: "section-export",
    computed: {
        imageHeight (): string {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return '300px'
                case 'sm': return '350px'
                case 'md': return '600px'
                case 'lg': return '800px'
                case 'xl': return '1000px'
            }
            return '0px';
        },
    }   
})
