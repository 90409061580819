






import Vue from 'vue'

export default Vue.extend({
    name: 'brand-contact',
    props: [
        'color',
        'icon'
    ]
})
