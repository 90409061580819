



























import Vue from 'vue'
import Github from './brands/Github.vue'
import Linkedin from './brands/Linkedin.vue'
import Twitter from './brands/Twitter.vue'

export default Vue.extend({
  components: {
      Twitter,
      Linkedin,
      Github
    },
    name: 'custom-footer'
})
