import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import Legal from '../views/Legal.vue'

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/legal',
    name: 'legal',
    component: Legal,
    meta: {
      title: "meta.legales"
    }
  },
  {
    path: '*',
    name: 'Home',
    component: Home,
    meta: {
      title: "meta.title",
    }
  }
]

const router = new VueRouter({
  routes,
  mode: "history"
})

export default router
