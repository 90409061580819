






























import Vue from 'vue'
import Github from '@/components/brands/Github.vue'

export default Vue.extend({
    name: "section-open-source",
    components: {
        Github
    },
    methods: {
        redirect() {
            window.open("https://github.com/lokappio", "_blank");
        }
    }
})
