
















import Vue from 'vue'
import LegalFR from '@/components/Legales/LegalFR.vue'
import LegalEN from '@/components/Legales/LegalEN.vue'
import Footer from '@/components/Footer.vue'

export default Vue.extend({
    components: {
        "legal-fr": LegalFR,
        "legal-en": LegalEN,
        "custom-footer": Footer
    },
    metaInfo() {
        let title = "Lokapp";
        let description = this.$t('meta.description');
        if (this.$route.meta) {
            title = this.$t(this.$route.meta.title).toString();
        }
        return {
            title: `${title}`,
            meta: [
                { charset: 'utf-8' },
                { name: 'description', content: `${description}` },
                { name: 'viewport', content: 'width=device-width, initial-scale=1' }
            ]
        };
    },
    data() {
        return {
            file: 'legal-' + this.$i18n.locale
        }
    },
    created() {
        window.scrollTo(0,0);
    }
})
