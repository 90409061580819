






import Vue from 'vue'

export default Vue.extend({
    name: "brand-github",
    props: [
        'color',
        'icon',
        'small'
    ],
    methods: {
        redirect() {
            window.open("https://github.com/lokappio", "_blank");
        }
    }
})
