






import Vue from 'vue'
import config from "@/config";

export default Vue.extend({
    name: 'cta-button',
    props: [
        'height'
    ],
    data() {
        return {
            url: config.demo
        }
    },
    methods: {
        redirect() {
            window.open(this.url, "_blank");
        }
    }
})
