














import Vue from 'vue'
import CTAButton from '../CTAButton.vue'

export default Vue.extend({
    name: "section-team",  
    components: { 
        'cta-button': CTAButton
    }
})
