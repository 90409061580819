







































































import Vue from 'vue'
import SECTION from '@/data/enum/sections.enum'
import Twitter from '@/components/brands/Twitter.vue'
import Linkedin from '@/components/brands/Linkedin.vue'
import Github from '@/components/brands/Github.vue'
import Contact from '@/components/brands/Contact.vue'

export default Vue.extend({
    name: "nav-bar",
    components: {
        Twitter,
        Linkedin,
        Github,
        Contact
    },
    data() {
        return {
            SECTION,
            showSections: false,
            navBarClass: 'background-no-blur',
            featuresSection: SECTION.TRANSLATIONS
        }
    },
    created () {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed () {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll() {
            var doc = document.documentElement;
            var top = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);

            if (top === 0) {
                this.navBarClass = 'background-no-blur';
            } else {
                this.navBarClass = 'background-blur';
            }
        },
        getSections(): string[] {
            const sections: string[] = [];
            Object.values(SECTION).forEach((section: string) => {
                sections.push(section);
            });
            return sections;
        }
    }
})
