















































import Vue from 'vue'
import CTAButton from '@/components/CTAButton.vue'
import ProductHuntButton from "@/components/ProductHuntButton.vue";

export default Vue.extend({
    name: 'section-title',
    components: {
      ProductHuntButton,
        'cta-button': CTAButton
    }
})
