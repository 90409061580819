













import NavBar from '@/components/NavBar.vue'
import Vue from 'vue'
import SECTION from '@/data/enum/sections.enum'
import Title from '@/components/sections/Title.vue'
import Translations from '@/components/sections/Translations.vue'
import Projects from '@/components/sections/Projects.vue'
import Exports from '@/components/sections/Exports.vue'
import Team from '@/components/sections/Team.vue'
import OpenSource from '@/components/sections/OpenSource.vue'
import Footer from '@/components/Footer.vue'

export default Vue.extend({
  name: 'Home',
  metaInfo() {
    let title = "Lokapp";
    let description = this.$t('meta.description');
    if (this.$route.meta) {
      title = this.$t(this.$route.meta.title).toString();
    }
    return {
      title: `${title}`,
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: `${description}` },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' }
      ]
    };
  },
  components: {
    NavBar,
    'custom-footer': Footer,
    'section-title': Title,
    'section-translations': Translations,
    'section-projects': Projects,
    'section-export': Exports,
    'section-team': Team,
    'section-open_source': OpenSource
  },
  methods: {
    getSections(): string[] {
      const sections: string[] = [];
      Object.values(SECTION).forEach((section: string) => {
        sections.push(section);
      });
      return sections;
    },
    getComponent(section: string): string {
      return 'section-' + section;
    }
  }
})
